@import "mixins";
@import "variables";
.prev,
.next {
  width: 48px;
  height: 48px;
  color: $primary-dark-color;
  cursor: pointer;
}

.middle {
  height: 48px;
  color: $primary-dark-color;
  cursor: pointer;
  @include font-main-bold;
}
