import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'hadsup-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnInit {
    @Input() isRequired: boolean = false;
    @Input() defaultText: string = 'Select item';
    @Input() labelName: string = 'Select item';
    @Input() items: any;
    @Input() value: any = '';
    @Input() isMultipleSelection = false;
    @Input() selectedItems: any[] = [];
    @Input() nameProp: any;
    @Input() valueProp: any;
    @Input() labelProp: any;
    @Input() displayValueProp = false;
    @Input() defaultValue: Number = -1;
    @Output() selectionChange = new EventEmitter<any>();
    @Input() hasRadio: boolean = false;
    dropdownActive: boolean = false;
    isDefaultSelection: any;
    selectedItemsCount: any;
    ngOnInit(): void {
        document.addEventListener('click', this.closeDropdown.bind(this));
        this.updateSelectedItemsCount();
    }
    selectItem(item: any): void {
        if (!this.isMultipleSelection) {
            this.selectionChange.emit(item);
            this.value = item;
            item.isSelected = true;
        }
        this.dropdownActive = false;
    }
    onMultipleSelect(item: any, event?: any): void {
        if (!item) return;
        item.isSelected = event ? event.checked : !item.isSelected;
        if (item[this.valueProp] === this.defaultValue) {
          this.items.forEach((element: any) => {
            element.isSelected = item.isSelected;
          });
        } else {
          const defaultItem = this.items.find((f: any) => f[this.valueProp] === this.defaultValue);
          if (defaultItem) {
            defaultItem.isSelected = this.items.every((el: any) => el.isSelected);
          }
        }
  
        this.selectedItems = this.items.filter((el: any) => el.isSelected);
        this.selectionChange.emit(this.selectedItems);
        this.updateSelectedItemsCount();
  
        if (!this.isMultipleSelection) {
          this.dropdownActive = false;
        }
  
        this.value = this.selectedItems?.length > 0 ? this.selectedItems.map(el => el.name).join(', ') : '';
      }

    toggleDropdown() {
        // document.querySelectorAll('.dropdown-menu').forEach((element) => {
        //     element.classList.remove('show');
        // });

        this.dropdownActive = !this.dropdownActive;
    }

    closeDropdown(event: any): void {
        if (
            !event.target.closest('.dropdown-menu') &&
            !event.target.closest('.dropdown-toggle-click')
        ) {
            this.dropdownActive = false;
        }
    }
    resetSelection(): void {
        this.items.forEach((item: any) => (item.isSelected = false));  
        this.selectedItems = [];
        this.selectionChange.emit([]);  
        this.selectedItemsCount = 0; 
      }
      updateSelectedItemsCount(): void {
        this.value =  this.selectedItemsCount = this.items.filter((item: any) => item.isSelected).length;
        this.selectedItems= this.selectedItemsCount = this.items.filter((item: any) => item.isSelected)
    }
}
