import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number): string {
    value = Math.floor(value / 1000);
    if (value < 60) {
      return `${value}sec`;
    } else if (value < 3600) {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;
      return `${minutes}min ${seconds}sec`;
    } else {
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      return `${hours}hr ${minutes}min`;
    }
  }

}
export function formatTime(value: number): string {
  value = Math.floor(value / 1000);
  if (value < 60) {
    return `${value} s`;
  } else if (value < 3600) {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${minutes}min ${seconds}s`;
  } else {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    return `${hours}hr ${minutes}min`;
  }
}
