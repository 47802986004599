import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup } from '@angular/forms';

@Component({
  template: ``
})
export class FormcontrolComponent implements ControlValueAccessor, OnInit {
  @Input()
  disabled: boolean = false;

  @Input()
  name: any;

  @Input()
  formControlName: any;

  @Input()
  placeholder: string = ' ';

  @Input()
  value: any = '';

  @Input()
  form!: FormGroup;

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnInit() {}

  change(value: any) {
    this.value = value || null;
  }
}
