import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { AnyPtrRecord } from 'node:dns';

@Injectable({
  providedIn: 'root',
})
export class SaleAgentService {
  rootUrl = `${environment.apiUrl}/api/v1/`;
  public encoder: HttpParameterCodec | undefined;
  constructor(private http: HttpClient) {}

  addAdvertiser(data: UserModel): Observable<any> {
    if (data) data['scopes'] = ['api'];
    const url: string = `${this.rootUrl}sales/add-advertiser`;
    return this.http.post<any>(url, data);
  }
  getAdvertiser(page: number, filter: any): Observable<any> {
    let queryParameters = new HttpParams()
      .set('page', page.toString())
      .set('per-page', '10');

    if (filter && filter.search) {
      queryParameters = queryParameters.append('search', filter.search);
    }

    if (filter && filter.filter) {
      const item = filter.filter;
      if (item.role !== undefined) {
        queryParameters = queryParameters.append(
          `filter[${item.id}]`,
          item.role
        );
      }
      const isLocal = item.name === 'Local';
      if (isLocal) {
        queryParameters = queryParameters.append('is_local', '1');
      }
    }
    const MethodName: string = 'sales/get-advertiser';
    const url: string = this.rootUrl + MethodName;
    return this.http.get(url, { params: queryParameters });
  }
  // AgentDashboard overview Api's
  agentDashboard() {
    const data = {
      country_id: 1,
    };
    const url: string = `${this.rootUrl}sales/${data.country_id}/dashboard`;
    return this.http.post<any>(url, data);
  }

  getRecentEarning(data: any) {
    const url: string = `${this.rootUrl}sales/1/earnings`;
    return this.http.post(url, data);
  }

  // sales agent add start
  addSalesAgent(data: UserModel) {
    if (data) data['scopes'] = ['api'];
    const url: string = `${this.rootUrl}sales/add-salesagent`;
    return this.http.post<any>(url, data);
  }
  getSalesAgent(page: number, filter: any): Observable<any> {
    let queryParameters = new HttpParams()
      .set('page', page.toString())
      .set('per-page', '10');

    if (filter && filter.search) {
      queryParameters = queryParameters.append('search', filter.search);
    }

    const MethodName: string = 'sales/get-salesagent';
    const url: string = this.rootUrl + MethodName;
    return this.http.get(url, { params: queryParameters });
  }
}
