// Primary font
$primary-font: "Inter", sans-serif;

// Primary Colors common use
$primary-white: #ffffff;
$primary-dark-color: #000000;
$primary-light-gray: #eaecf0;
$primary-dark-gray: #666666;
$primary-light-gray-1: #485663;
$primary-light-gray-2: #d0d5dd;
$primary-dark-gray-3: #f5f8ff;
$primary-light-gray-4: #475467;
$primary-dark-gray-4: #cccccc;
$primary-light-blue: #a2b0bd;
$primary-dark-blue: #2a3845;
$primary-dark-blue-1: #485663;
$primary-med-blue-1: #84929F;
$primary-med-blue: #667481;
$primary-light-gray-3: #e7e7e7;

$color-dark: #232c34;
// Default Theme
$primary-default-dark: #0c111d;
$color-light: #f9fafb;
$primary-box-color: #f4e4eb;
$primary-box-border-color: #ff63ae33;

// Accent Colors
$accent-cyan: #8bfff8;
$accent-cyan-muted: #63d7d0;
$accent-red: #f46872;
$accent-dark-red: #e0525f;
$color-green: #70c86a;
$color-green-muted: #5cb456;
$color-green-dark: #17b26a;
$color-yellow: #fac64c;
$color-yellow-muted: #e6b238;

// Breakpoints

$xs-min: 414px;
$xs-max: 575.98px;
$sm-min: 576px;
$sm-max: 767.98px; // main-wrapper 300px
$md-min: 768px;
$md-max: 991.98px;
$lg-min: 992px;
$lg-max: 1199.98px; // main-wrapper 688px
$xl-min: 1200px;
$xl-max: 1439.98px;
$xxl-min: 1440px;
$xxl-max: 1535.98px;
