import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Loader } from '@googlemaps/js-api-loader';
import { FormcontrolComponent } from './formcontrol.component';
import { environment } from '../../../environments/environment';
const mapApiKey: any = environment.mapApi;
const loader: any = new Loader({
  apiKey: mapApiKey,
  version: "weekly",
  libraries: ["places"]
});

let google: any = null;
@Component({
  selector: 'hadsup-g-autocomplete',
  template: `
    <div 
      (focusin)="focus = !focus"
      (focusout)="!(focus = focus)"
      [class.button-focused]="focus"
    >
      <!-- <label class=""
        >{{ name }} 
        </label
      > -->
      <input
        #input
        type="text"
        autocomplete="off"
        [value]="value"
        [attr.maxlength]="maxlength"
        [placeholder]="placeholder"
        (change)="change($event)"
      />
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoogleplaceAutocompleteComponent),
      multi: true
    }
  ]
})
export class GoogleplaceAutocompleteComponent extends FormcontrolComponent implements AfterViewInit {
  @Input() maxlength: number = 999;
  @Input() required: boolean = false;
  @Output() private onChangePlace: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('input')
  private input!: ElementRef;
  public focus: boolean = false;

  changed: boolean = false;

  lat!: number;
  lng!: number;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    if (google) {
      return this._initAutocomplete();
    }
    loader.load().then((g: any) => {
      google = g;
      this._initAutocomplete();
    });
  }

  private _initAutocomplete() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.input.nativeElement
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      let place = autocomplete.getPlace();
      this.input.nativeElement.value = place.formatted_address;

      this.onChangePlace.emit(place);
      this.propagateChange(this.input.nativeElement.value);
    });
  }

  override change(e: any) {
    this.propagateChange(e.target.vaue);
  }
}
