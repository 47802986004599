import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MustMatch } from '../../validators/must-match.validator';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'hadsup-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  isLoading: boolean = false;
  success: boolean = false;
  isNewPassword: boolean = false;
  isConfirmPassword: boolean = false;
  newPasswordType = 'password';
  confirmPasswordType = 'password';
  submitted: boolean = false;
  salesPage = `${environment.hadsup_home_url}sales`;
  salesType:any
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private storageservice: StorageService,
    private toastr: ToastrService,
  ) {
    this.resetPasswordForm = fb.group(
      {
        email: new FormControl({ value: '', disabled: true }, [
          Validators.required,
          Validators.email,
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        password_confirmation: new FormControl('', [Validators.required]),
        token: new FormControl(''),
      },
      {
        validator: MustMatch('password', 'password_confirmation'),
      }
    );
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const email = params['email'];
      if (email) {
        this.resetPasswordForm.controls['email'].setValue(email);
      }
      const token = params['token'];
      if (token) {
        this.resetPasswordForm.controls['token'].setValue(token);
      }
      this.salesType = params['type'];
      if (this.salesType) {
          this.resetPasswordForm.controls['type']?.setValue(token);
      }
    });
  }

  submit() {
    if (this.isLoading) {
      return; 
  }
    this.submitted = true;
    if (this.salesType) {
      this.advertiserPasswordReset();
    } else {
    if (this.resetPasswordForm.valid) {
      const submittedForm = this.resetPasswordForm.getRawValue();
      this.isLoading = true;
      this.authService
        .ResetPassword(submittedForm)
        .subscribe(
          async (response: any) => {
            this.success = response.success;
           this.toastr.success('Your password has been reset. Try login again with the new password','Success')
           setTimeout(async () => {
            await this.storageservice.removeAccessToken();
            this.router.navigate(['/login']);
          }, 2000);
          },
          (err: any) => {
            console.log(err);
           this.storageservice.removeAccessToken();
          }
        )
        .add(() => {
          this.isLoading = false;
        });
    }
  }
  }
  advertiserPasswordReset(): void {
    this.submitted = true;
    if (this.resetPasswordForm.valid) {
      const submittedForm = this.resetPasswordForm.getRawValue();
      this.isLoading = true;

      this.authService.saleAdvertiserPasswordReset(submittedForm).subscribe(
        response => {
          this.success = response.success;
          this.toastr.success('Your password has been reset. Try login again with the new password','Success')
          setTimeout(async () => {
            this.router.navigate(['/login']);
          }, 2000);
        },
        err => {
          console.error(err);
        }
      ).add(() => {
        this.isLoading = false;
      });
    }
  }
  hideShow(type: string) {
    if (type === 'NEW') {
      this.isNewPassword = !this.isNewPassword;
      this.newPasswordType = this.isNewPassword ? 'text' : 'password';
    }
    if (type === 'CONFIRM') {
      this.isConfirmPassword = !this.isConfirmPassword;
      this.confirmPasswordType = this.isConfirmPassword ? 'text' : 'password';
    }
  }
}
