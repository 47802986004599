import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {
  
  constructor() {}

  getUserCreatedDate(created_at: number): { start: Date, end: Date, minDate: Date, maxDate: Date } {
    if (!created_at) return { start: new Date(), end: new Date(), minDate: new Date(), maxDate: new Date() };

    const createdDate = new Date(created_at * 1000);
    const currentDate = new Date();
    const backDate = new Date(currentDate);
    backDate.setDate(backDate.getDate() - 1);

    return {
      start: createdDate,
      end: backDate,
      minDate: createdDate,
      maxDate: backDate
    };
  }
}
