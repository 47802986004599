import { IClient } from "./client.interface";
import { IBalance, ICurrency } from "./generic.interface";

export interface IUser {
    id: number;
    email: string;
    user_name: string;
    relations:IRelation;
    account_number: string;
    country_id:number;
    phone:string;
    address:string;
    street: string;
    town: string;
    postcode: string;
    address_country:string;
    permissions:string[];
    hand_based_verification_status:any;
    profile_photo:string;
    created_at:any;
}


export interface IRole {
    id: number;
    slug: string;
    name: string;
    name_en: string;
    relations: any[];
}
export interface IRelation{
    roles:IRole[];
    country:any;
    currency:ICurrency;
    balance:IBalance;
    allBalances:IBalance[];
    clients:IClient[];
  }
  export enum UserTypeName {
    Sales = 'Sales',
}
